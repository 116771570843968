html {
  height: 100%;
  overflow: hidden;
}
body {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.slider,
.vertical-container,
.parallax-container,
.slide {
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.sticky-header {
  @apply py-4;
  box-shadow: 0px 10px 40px 20px #00000099;
  background: #121010;
}

.slide__image {
  @apply absolute top-0 left-0 w-full h-full overflow-hidden;
}

.slide__image-1 {
  @apply z-0;
}

.slide__image-2 {
  @apply z-10 max-md:-skew-y-12 max-md:border-b-[8px] md:-skew-x-12 md:border-r-[13px] md:-ml-[10%];
}

.slide__image-2 img {
  @apply z-10 max-md:skew-y-12 md:skew-x-12;
}

.slide__image img {
  @apply w-[110vw] min-w-[110vw] h-full min-h-[100vh] object-cover object-[50%,100%];
}

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.parallax-image {
  @apply absolute top-0 left-0 w-full h-full object-cover object-[50%,100%];
}

.slice_slide-border {
  @apply border-r-[13px];
}

.parallax-container > * {
  @apply -skew-x-[20deg];
}

.gradient-to_right {
  background: linear-gradient(
    160deg,
    #fcad16 9.49%,
    #f96e0e 39.02%,
    #f44004 68.56%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gradient-to_left {
  background: linear-gradient(
    229.28deg,
    #fcad16 -2.1%,
    #f96e0e 36.71%,
    #f44004 75.52%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .slider {
  @apply top-0 left-[30vw] md:left-[10vw];
  transform: translateX(8400px) scaleX(-1);
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
} */

.slider {
  @apply top-0;
  /* transform: translateX(8400px) scaleX(-1); */
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  margin-left: calc(100vh * tan(20deg) / 2);
}

/* .slide {
  @apply w-full min-w-[160vw] md:min-w-[120vw]  h-screen relative overflow-hidden;
} */

.slide {
  @apply w-full h-screen relative overflow-hidden;
  /* min-width: calc(100%*(1 + cos(20deg))); */
  min-width: calc(100vw + 100vh * tan(20deg));
}

.slice_slide {
  @apply -skew-x-[20deg] -scale-x-[1];
}

.slice_slide-reverse {
  @apply skew-x-[20deg] -scale-x-[1];
}

.vertical-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: none;
  scroll-behavior: smooth;
}

/* Для обычного скролла */
/* .vertical-container {
  position: relative; 
  width: 100%;
  height: 100vh;    
  overflow-y: auto;  
  scroll-behavior: smooth;
} */

.vertical-container .content_slide {
  @apply min-w-[100vw] min-h-[100vh] h-screen flex flex-col justify-center items-center; /* border-2 border-red-500; */
}

.slide-conteiner {
  @apply w-full  max-xl:mx-auto xl:px-[8%] px-4 md:px-6 lg:px-16;
}

.about_slide {
  @apply overflow-visible;
}

.about_slide:before {
  content: "";
  position: absolute;
  top: 0;
  right: -70vw;
  width: calc(100% + 70vw);
  height: 100%;
  transform: skewX(20deg);
  transform-origin: bottom left;
  background: linear-gradient(
    160deg,
    #fcad16 9.49%,
    #f96e0e 39.02%,
    #f44004 68.56%
  );
}

.hero_btn-container > .hero_btn-2:hover {
  @apply bg-[#FF6A13];
}

.hero_btn-container:hover > .hero_btn-2:hover ~ .hero_btn-1 {
  @apply bg-transparent;
}

/* screen_btn */

.screen_btn {
  box-shadow: inset 0px 10px 12px 0px #fda337;
  border-radius: 50%;
  background: #fc6d25;
  height: 72px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.btn_shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}

.shadow_1 {
  background: #ffc267;
  opacity: 0.1;
}

.shadow_2 {
  background: #ff774d;
  opacity: 0.1;
}

.screen_btn > div {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #fc6d25;
  box-shadow: inset 0px 10px 12px 0px #fda337;
}

.screen_btn::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(31deg, #fb8224 0%, #ffe6b1 100%);
  border-radius: 50%;
  z-index: -1;
}

.content-inner {
  @apply w-full h-fit;
}

/* .shape {
  width: 100%;
  height: 400px;
  background: linear-gradient(45deg, #FF6A13, #FFC38C);
  clip-path: polygon(45% 0, 55% 10%, 100% 10%, 100% 100%, 0 100%, 0 0);
  /* clip-path: polygon(45% 0, 55% 10%, 100% 10%, 100% 100%, 0 100%, 0 0);
    clip-path: path("M45% 0 A10 10 0 0 1 55% 10
                    H100%
                   V100%
                   H0
                   V0
                   Z");
} */

/* shape_box */

.shape_box {
  @apply text-white max-w-[1260px] mx-auto overflow-hidden rounded-3xl;
}

.shape_box-header {
  @apply relative w-[50%];
  margin-bottom: -1px;
}

.shape_box-header > div {
  @apply bg-[#FF6A13] text-4xl font-medium leading-none uppercase italic h-[36px] md:h-[46px] lg:h-[61px] w-[calc(100%-36px*tan(30deg))] md:w-[calc(100%-46px*tan(30deg))] lg:w-[calc(100%-61px*tan(30deg))]  pb-[1px] z-10 relative;
  border-width: 1px 0 0 1px;

  border-style: solid;
  border-color: white;
  border-radius: 24px 0 0 0;
}

.shape_box.reverce {
  @apply flex flex-col items-end;
}

.shape_box.reverce .shape_box-body {
  border-radius: 24px 0 24px 24px;
}

.shape_box.reverce .shape_box-header {
  @apply scale-x-[-1];
}

.shape_box:not(.reverce) .shape_box-header > div span {
  @apply absolute top-0 left-0 w-full pl-5 lg:pl-9 pt-3 lg:pt-5;
}

.shape_box.reverce .shape_box-header > div span {
  @apply absolute top-0 right-0 w-full scale-x-[-1] block text-end pr-5 lg:pr-9 pt-3 lg:pt-5;
}

.shape_box-header > div:before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #ff6a13;
}

/* .shape_box-header:before {
  content: "";
  bottom: 1px;

  left: calc(100% - 9px);
  --tw-skew-x: 24deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 150%;
  height: 200%;
  display: block;
  position: absolute;
  outline: blue solid 35px;
  border-radius: 24px;
  z-index: 1;
  border: 1px solid white;
  clip-path: inset(65% 65% 0% -100%);

}

.shape_box-header::after {
  content: "";
  bottom: 1px;
  left: calc(100% - 9px);
  --tw-skew-x: 24deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 150%;
  height: 200%;
  display: block;
  position: absolute;
  outline: red solid 35px;
  border-radius: 24px;
  z-index: 1;
  border: 1px solid white;
  clip-path: inset(65% 65% 0% -100%);

} */

.shape_box-header:before {
  @apply skew-x-[30deg];
  content: "";
  background: #ff6a13;
  bottom: 1px;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 24px;
  border: 1px solid white;
  z-index: 1;
  /* clip-path: inset(0% 50% 0% 0%); */
}

.shape_box-header-transparent {
  background: transparent;
  bottom: 0px;
  left: 100%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}

.shape_box-header::after {
  @apply skew-x-[30deg];
  background: transparent;
  content: "";
  bottom: 0px;
  left: 100%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  outline: #ff6a13 solid 100vw;
  border-radius: 24px;
  z-index: 2;
  border: 1px solid white;
  clip-path: inset(50% 50% -108% -100%);
  /* pointer-events: none; */
}

.shape_box-body {
  @apply w-full flex max-lg:flex-col lg:items-center gap-y-8 gap-x-14 p-9 bg-[#FF6A13] border-[1px] border-solid border-white py-10 px-9 lg:px-20 lg:py-20;
  border-radius: 0 24px 24px 24px;
}

.shape_box-img {
  @apply relative aspect-video lg:w-[50%];
}

.shape_box-img:after {
  content: "";
  background: radial-gradient(
    56.15% 56.15% at 50% 50%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  display: block;
  position: absolute;
  top: 83%;
  width: 100%;
  height: 90px;
  z-index: -1;
}

.shape_box-img img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

.after-img-container {
  @apply -ml-[calc((100%*9/16*tan(20deg)/2))] w-[calc(100%+100%*9/16*tan(20deg))] h-full overflow-hidden skew-x-[-20deg];
  /* width: calc(100% + 100%* 9 / 16* tan(20deg)); */
  /* margin-left: calc((100% * 9 / 16 * tan(20deg) / 2) * -1); */
}

.after-img-container img {
  min-width: calc(100% + 100% * 9 / 16 * tan(20deg));
}

.shape_box-text {
  @apply z-10 lg:w-[50%] font-whitney leading-[110%];
}

.shape_box-img {
  @apply z-10;
}

.floating-image {
  animation: upDown 3s ease-in-out infinite;
  will-change: transform;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7vh); /* можно изменить величину смещения */
  }
  100% {
    transform: translateY(0);
  }
}

.animate {
  transition: transform 0.6s linear;
}
/* 
.vertical-container {
  scroll-snap-type: y proximity;

}

.content_slide {
  scroll-snap-align: center;
} */

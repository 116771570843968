@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-LightItal.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-BoldItal.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-XBlackItal.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-ThinItal.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-XThin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "PFDinTextCompPro";
  src: url("../fonts/PFDinTextCompPro-XThinItal.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-BookItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Whitney";
  src: url("../fonts/Whitney-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

@import "./fonts.css";
@import "./slider.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-outline-1 {
    color: transparent;
    -webkit-text-stroke: 1px white;
  }

  .text-outline-3 {
    color: transparent;
    -webkit-text-stroke: 3px white;
  }
}

html {
  background: black;
}

html,
body {
  overflow-y: hidden;
  /* background: black; */
}

* {
  @apply select-none;
}

.height-mobile {
  height: calc(var(--vh, 1vh) * 100);
}

.min-height-mobile {
  min-height: calc(var(--vh, 1vh) * 100);
}

.block_conteiner{
  @apply max-w-[1600px] mx-auto px-6 w-full;
}

.text-stroke-3 {
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}

/*  */

.burger-menu {
  @apply absolute top-4 lg:top-8 right-4 lg:right-8 z-50;
}

.menu-logo img {
  @apply w-56;
}

.no_scroll::-webkit-scrollbar{
  display: none;
}
